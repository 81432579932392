import React from 'react'
import Head from 'next/head'

interface DefaultSEOProps {
  title: string
  description: string
  url: string
  ogImage?: string
  children?: React.ReactNode
}

export const DefaultSEO: React.FC<DefaultSEOProps> = ({
  title,
  description,
  url,
  ogImage,
  children,
}) => {
  // Use the provided ogImage or fall back to the default one
  const imageUrl = ogImage || 'https://supermeme.ai/static/og-image.png'

  return (
    <Head>
      <title>{title}</title>
      <meta name='description' content={description} />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />
      <meta property='og:url' content={url} />
      <meta property='og:image' content={imageUrl} />
      <meta property='og:type' content='website' />
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:title' content={title} />
      <meta name='twitter:description' content={description} />
      <meta name='twitter:image' content={imageUrl} />
      <link rel='canonical' href={url} />
      <meta name='viewport' content='width=device-width, initial-scale=1' />
      {children}
    </Head>
  )
}
